/* eslint-disable */
/* prettier-disable */

/**
 * Initiate MicroModel
 *
 */

if (typeof MicroModal !== 'undefined') {
    MicroModal.init();
}


jQuery(document).on('ready', function () {
    if (typeof jQuery.prototype.niceSelect === "function") {
        jQuery('select.dropdown').niceSelect({
            // inheritOriginalWidth : true
        });
    }
});

window.addEventListener('message', function (event) {

    if (event.data.type === 'hsFormCallback') {
        var form_id = event.data.id;


        if (event.data.eventName === 'onBeforeFormInit') {

            jQuery('.hubsport-form .hs-form select.hs-input').parent().addClass('select-wrapper');

            // jQuery('.hubsport-form .hs-form select.hs-input').niceSelect({
            //     // inheritOriginalWidth : true
            // });

            var attrObserver = new MutationObserver((mutations) => {
                mutations.forEach(function (mu) {
                    if (mu.type !== "attributes" && mu.attributeName !== "class") return;


                    jQuery(mu.target).closest('.hs-form-field').find('label').addClass('error');

                    if (jQuery(mu.target).hasClass('error')) {

                        if (mu.target.tagName.toLowerCase() === 'select') {

                            jQuery(mu.target).next().addClass('invalid error');

                            jQuery(mu.target).on('change', function () {
                                jQuery(this).next().removeClass('invalid error');
                            })
                        }

                    } else {
                        jQuery(mu.target).closest('.hs-form-field').find('label').removeClass('error');
                    }
                });
            });

            var input_fields = document.querySelectorAll('.hs-input');
            input_fields.forEach(function (el) {
                attrObserver.observe(el, {attributes: true})
            });


            // // Example of Buttons toggling several .test classNames
            // document.querySelectorAll('.hs-input').forEach(btn => {
            //     btn.addEventListener("click", () => input_fields.forEach(el => el.classList.toggle(btn.dataset.class)));
            // });
        }

    }
});

/**
 *
 *
 * ==================================
 * BLOG PAGE
 * ==================================
 *
 **/


var blog_posts_wrapper = jQuery('#blog_posts'),
    blog_filter_by_input = jQuery('#blog_filter_by'),
    blog_sort_by_input = jQuery('#blog_sort_by');

function create_blog_page_filters_url(param, value) {
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var filter_by_param = url.searchParams.get('filter_by');
    var sort_by_param = url.searchParams.get('sort_by');

    var url_params = {}

    if (filter_by_param) {
        url_params['filter_by'] = filter_by_param;
    }

    if (sort_by_param) {
        url_params['sort_by'] = sort_by_param;
    }

    if (param === 'filter_by') {
        url_params['filter_by'] = value;
    }

    if (param === 'sort_by') {
        url_params['sort_by'] = value;
    }

    return url.origin + url.pathname + '?' + new URLSearchParams(url_params);

}

blog_filter_by_input.on('change', function (e) {
    e.preventDefault();
    window.location.replace(create_blog_page_filters_url('filter_by', jQuery(this).find(":selected").val()));
})

blog_sort_by_input.on('change', function (e) {
    e.preventDefault();
    window.location.replace(create_blog_page_filters_url('sort_by', jQuery(this).find(":selected").val()));
});


/**
 *
 *
 * ==================================
 * MARKETING FAQ SECTION
 * ==================================
 *
 **/

var marketing_faq_container = jQuery('.marketing-faq-section'),
    marketing_faq_section = marketing_faq_container.find('.custom-columns .custom-column'),
    marketing_faq_show_more_wrapper = marketing_faq_container.find('.show-more-wrapper'),
    marketing_faq_show_more_btn = marketing_faq_show_more_wrapper.find('.show-more');

if (marketing_faq_container.length > 0) {

    if (marketing_faq_section.length > 1) {
        marketing_faq_section.addClass('faq-mobile-hidden');
        marketing_faq_section.slice(0, 2).removeClass('faq-mobile-hidden');

        marketing_faq_show_more_btn.on('click', function (e) {
            e.preventDefault();
            marketing_faq_section.removeClass('faq-mobile-hidden');
            marketing_faq_show_more_wrapper.hide();

            if (marketing_faq_section.eq(2).length > 0) {
                jQuery('html, body').animate({
                    scrollTop: marketing_faq_section.eq(2).offset().top - 25
                }, 800);
            }
        })
    } else {
        marketing_faq_show_more_wrapper.hide();
    }
}

/**
 *
 *
 * ==================================
 * MEMBER PORTAL SECTION
 * ==================================
 *
 **/

var member_portal_container = jQuery('.member-portal-navigation'),
    member_portal_container_btns = member_portal_container.find('.btn');

if (member_portal_container.length > 0) {

    member_portal_container_btns.on('click', function (e) {
        e.preventDefault();

        var id = jQuery(this).attr('href');

        if (jQuery(id).length > 0) {
            jQuery('html, body').animate({
                scrollTop: jQuery(id).offset().top - 25
            }, 800, function () {
                history.pushState({}, '', id);
            });
        }
    });
}

/**
 *
 *
 * ==================================
 * TABS WPBAKERY
 * ==================================
 *
 **/

jQuery(function ($) {
  var tabs_sections = $(".sasb-tabs-container");

  if (tabs_sections.length > 0) {
    tabs_sections.each(function (index, tabs) {
      var tabs_container = $(tabs).find(".sasb-tabs"),
        active_tab_index = parseInt(tabs_container.data("active-tab")),
        tabs_nav = tabs_container.find(".tabs-nav"),
        tabs_nav_links = tabs_nav.find(".item .link"),
        tabs_content = tabs_container.find(".content-wrap");

      tabs_nav
        .find(".item")
        .eq(active_tab_index)
        .find(".link")
        .addClass("active");
      tabs_content.find("section").hide();
      tabs_content.find("section").eq(active_tab_index).show();

      tabs_nav_links.each(function (index, link) {
        $(link).on("click", function (e) {
          e.preventDefault();
          var id = $(this).data("href");
          var hash = $(this).attr("data-href-hash");
          window.location.hash = hash;

          tabs_nav_links.removeClass("active");
          tabs_content.find("section").hide();
          tabs_content.find("section" + id).show();
          $(this).addClass("active");
        });
      });

      if (window.location.hash && !/^#:~:text=/.test(window.location.hash)) {
        var id = "#section-" + window.location.hash.slice(1);

        if (document.getElementById(id)) {
          $(".tabs-nav .item .link").removeClass("active");
          $(".tabs-nav-mobile .item").removeClass("active");
          tabs_content.find("section").hide();
          tabs_content.find("section" + id).show();
          $('.link[data-href="' + id + '"]').addClass("active");
          $('.link[data-href="' + id + '"]')
            .parent()
            .addClass("active");
          $("html,body").animate(
            { scrollTop: $(id).offset().top - 150 },
            "slow"
          );
        }
      }
    });
  }
});


/**
 *
 *
 * ==================================
 * TEAMS FORMS
 * ==================================
 *
 **/

var team_members = jQuery('.team-members .member .link');

team_members.each(function (index, member) {
    jQuery(member).on('click', function (e) {
        e.preventDefault();

        var container = jQuery(this).parent('.member'),
            image = container.find('.thumbnail').get(0).src,
            name = container.find('.title').text(),
            position = container.find('.position').text(),
            location = container.find('.location').text(),
            content = container.find('.content').html();

        jQuery.fancybox.open({
            // Base template for layout
            type: 'html',
            opts: {
                afterLoad: function (instance, current) {
                    console.info('done!');
                    jQuery('.fancybox-content').append(
                        '<div class="team-member-modal">' +
                        '<div class="row">' +
                        '<div class="md-6">' +
                        '<div class="inner">' +
                        '<img src="'+ image + '" class="img" />' +
                        '</div>' +
                        '</div>' +
                        '<div class="md-10">' +
                        '<h1 class="title">' + name + '</h1>' +
                        '<p class="position">' + position + '</p>' +
                        '<p class="location">' + location + '</p>' +
                        '<div class="content">' + content + '</div>' +
                        '</div>' +
                        '</div>' +
                        '</div>'
                    );
                }
            }
        });
    })
})

/**
 *
 *
 * ==================================
 * SICS FORMS
 * ==================================
 *
 **/

var sasb_sics_forms = jQuery(".sasb-sics-form-wrapper");

if (sasb_sics_forms.length > 0) {
    var params = {
    action: "sasb_sics_get_data",
    search: "",
  };

    sasb_sics_forms.each(function (index, sasb_sics_form) {
    var sasb_sics_form_el = jQuery(sasb_sics_form).find(".sasb-sics-form"),
      sasb_sics_warning = jQuery(sasb_sics_form).find(".sics-warning"),
      sasb_sics_info = jQuery(sasb_sics_form).find(".sics-info"),
      sasb_load_more = jQuery(sasb_sics_form).find(".sasb-sics-load-more"),
      input = jQuery(sasb_sics_form).find("input");

    const reset = function () {
      const sasb_sics_results = jQuery(sasb_sics_form).find(".sics-results");
      sasb_sics_results.data("page", 1);
      sasb_sics_results.html("");
      sasb_sics_info.text("");
      sasb_load_more.removeClass("flex");
      sasb_load_more.addClass("hidden");
    };

    const getURL = function ({ page, query }) {
      const current = new URL(window.location);
      const locale = current.searchParams.get("lang") || "en-gb";
      const { restEndpoints } = window.SASB || {};
      const endpoint = restEndpoints && restEndpoints.sicsSearch;
      const limit = 10;

            const url = new URL(endpoint);

            url.search = new URLSearchParams({
        s: query,
              locale: locale,
              format: "html",
              page,
              limit,
            });

      return url;
    };

    const showResults = function ({ sasb_sics_results, data, append }) {
            if (data && data.html) {
              if (append) {
          // Show Load More button if we're appending.
                sasb_load_more.find("a").removeClass("hidden");
                sasb_load_more.find("p").addClass("hidden");

                const rows = jQuery(data.html).find("tr").slice(1);
                sasb_sics_results.find("tbody").append(rows);
              } else {
              sasb_sics_results.html(data.html);
              }

              const currentCount = sasb_sics_results.find("tr").length - 1;
              sasb_sics_info.text(
                `${
                  data.pagination.totalRecords
                } total results found. Showing ${currentCount} result${
                  currentCount === 1 ? "" : "s"
          }.`,
              );

              if (data.pagination.nextPage) {
                sasb_load_more.removeClass("hidden");
                sasb_load_more.addClass("flex");
                sasb_sics_results.data("page", data.pagination.nextPage);
              } else {
                sasb_load_more.removeClass("flex");
                sasb_load_more.addClass("hidden");
              }
            }
    };

    // Fetch function
    const fetchSics = async function ({ append }) {
      const sasb_sics_results = jQuery(sasb_sics_form).find(".sics-results");
      const page = sasb_sics_results.data("page");
      const query = jQuery(sasb_sics_form).find(".form-control").val();

      if (!query || (query && query.length < 1)) {
        sasb_sics_warning.removeClass("hidden");
      } else {
        if (append) {
          // Hide load More button during load
          sasb_load_more.find("a").addClass("hidden");
          sasb_load_more.find("p").removeClass("hidden");
        } else {
          // Prepare to load fresh query data
          reset();
          sasb_sics_results.html('<p class="text">Loading ...</p>');
        }

        sasb_sics_warning.addClass("hidden");

        try {
          const response = await fetch(getURL({ page, query }));
          const data = await response.json();

          showResults({ sasb_sics_results, data, append });
          } catch (error) {
            console.log("Error fetching sics", error);
            sasb_sics_info.text(
              "Unable to load results. If the issue continues, please email us at info@ifrs.org.",
            );
          }
      }
    };

    // Reset the results if the input changes
    input.on("input", function () {
      reset();
    });

    // Handle Load More button
    sasb_load_more.on("click", async function (e) {
      e.preventDefault();
      await fetchSics({ append: true });
    });

    // Submit search
    sasb_sics_form_el.on("submit", async function (e) {
      e.preventDefault();
      await fetchSics({ append: false });
        });

        return false;
  });
}